$(function(){
	$(document).on("click", ".multi-checkbox", function(){

		const option1 = $(".option-1", this);
		const option2 = $(".option-2", this);
		const icon = $("i", this);
		
		if( !option1.prop("checked") && !option2.prop("checked") ){
			option1.prop( "checked", true );
			icon.addClass("active");
			icon.removeClass("inactive");
			option1.trigger('change');
		}
		else if( option1.prop("checked") ){
			option2.prop( "checked", true );
			icon.addClass("inactive");
			icon.removeClass("active");
			option2.trigger('change');
		}
		else if( option2.prop("checked") ){
			option2.prop( "checked", false );
			icon.removeClass("active");
			icon.removeClass("inactive");
			option2.trigger('change');
		}

		

	});
})
