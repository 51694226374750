var isLoading = true;
var tilesContainer = $(".tiles-container, .order-container");
var autoload = 0;

function scrollPaginator(){
    if($(".filterReload").length > 0){
        const filters = $(".filterReload").serialize();
	}
	const top = $(window).scrollTop();
	const height = $(window).height();

	if(((top / height) > 0.25) && isPagination){

		currentPage++;
		isPagination = false;

        $(".preloader").animate({
			opacity : 1
		});

        ajaxLoad();

	}
}

function ajaxLoad(){
    isLoading = false;
    autoload++;
    $.ajax({
        type 	: "GET",
        url 	: window.location.href,
        data 	: {page:currentPage},
        success: function(data) {

            tilesContainer.append(data.render);

            setSelect2();

            tilesContainer.trigger('paginated');

            if(data.lastPage){
                // console.log("megáll");
                return false;
            }

            isPagination = true;
        },
        error: function(data) {
               //console.log(data)
        },
        complete: function() {
            $(".preloader").animate({
                opacity : 0
            });
            // console.log("paginated...");
            isLoading = true;

            if(autoload < 2){
                scrollPaginator()
            } else {
                autoload = 0;
            }
        }
    });
}

window.addEventListener('scroll', () => {
    // Ellenőrizzük, hogy elérte-e a felhasználó az oldal alját
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 800 && isLoading) {

        // Meghívjuk a betöltés függvényt
        scrollPaginator();
    }
});


