const { ajax, data } = require("jquery");

var ajaxChangeTimer;
var isChangeEnable = true;

$(function(){

	//változtatásnál automentés
    $(document).on("change | keydown", ".change-send-ajax", function(e){
        // console.log("valami változik");

        const elem = $(e.target);
        const form = $(this);
        const url = form.attr("action");
        const type = form.attr("method");
        const data = form.serialize();

        if(isChangeEnable){
            elem.css("background", "lightyellow");

            isChangeEnable = false;

            clearTimeout(ajaxChangeTimer);

            ajaxChangeTimer = setTimeout(function(){
                isChangeEnable = true;
            },1000);

            $.ajax({
                type: type,
                url: url,
                data: data,
                success: function (data) {
                    // console.log("SUCCESS"+url);
                    elem.css("background", "transparent");
                    elem.css("border-color", "#ced4da");
                },
                error: function (e) {
                    console.log("ERROR : ", e);
                    elem.css("border-color", "red");
                }
            });
        }
    });

    //nézetváltások
    $(document).on('change', '[name="view"], .auto-reload-wrapper input', function(){
        $(".filterForm").submit();
    });
    $(document).on('click', ".goToIndexView", function(e){
        e.preventDefault();
        $('[data-view="list"]').trigger("click");
        $("#image-properties-editor").modal("hide");
    });
    //szűrőre kattintásnál ajax újratöltés
    $(document).on("submit", ".filterForm", function(e){
        e.preventDefault();

        preloader.fadeIn();

        $('.select-all').removeClass('all-selected').attr('data-original-title', 'Összes kijelölése');
        $(".selected-images-action").addClass('disabled');
        selectedImages = [];

        const form = $(this);
        const url = window.location.href.split("?")[0] + "?";
        const data = form.serialize();

        var check = new FormData(form[0]);

        if(
            check.get('view') == 'group'
            && (
                typeof data['img_ids'] != 'undefined'
                || typeof data['tags'] != 'undefined'
                || typeof data['keywords'] != 'undefined'
                || typeof data['photographers'] != 'undefined'
                || data.search('search%5B') != -1
                || typeof data['isactive'] != 'undefined'
                || typeof data['ispublic'] != 'undefined'
                || typeof data['ispublicsale'] != 'undefined'
                || typeof data['isstock'] != 'undefined'
                || typeof data['ismodell'] != 'undefined'
                || typeof data['iswpermission'] != 'undefined'
                || typeof data['iswpermission'] != 'undefined'
                || typeof data['isnew'] != 'undefined'
                || typeof data['ismediastop'] != 'undefined'
            )

        ){
            data['view'] = 'index';
            $('input[data-view="index"]').prop('checked',true);
        }

        window.history.pushState(false, "", url + data);

        var check = new FormData(form[0]);

        if(check.get('view') == 'group'){
            $('.change-submit-form').css('display','none');
        }
        else{
            $('.change-submit-form').css('display','inline');
        }


        $.ajax({
            type: "GET",
            data: data,
            enctype: 'multipart/form-data',
            url: url,
            dataType : "json",
            success: function (data) {
                $.each($('[name="view"]'), function(index, el) {
                    if($(el).prop("checked")){
                        $('.tiles-container').attr('data-view', $(el).val());
                    }
                });
                $(".tiles-container").html(data.render);
                $(".resultCount").text(data.count)

                currentPage = 1;
                isPagination = true;

                setSelect2();
                $('[data-toggle="tooltip"]').tooltip();

                preloader.fadeOut();

            },
            error: function (e) {
                alert("hiba történt");
                console.log("ERROR : ", e);
                preloader.fadeOut();
            }
        });

    });

    //ajax (modal) mentés
    $(document).on("click", ".modal-save", function(e){
		e.preventDefault();

    	const form = $(this).parents("form");
    	const url = form.attr("action");
    	const data = form.serialize();

    	preloader.fadeIn();

    	$.ajax({
    		url: url,
    		type: 'POST',
    		data: data
    	})
    	.done(function(data) {
    		// console.log(data);
    		// console.log("mentve");
    		$("#admin-edit-modal").modal("hide");
    		preloader.fadeOut();
    	})
    	.fail(function(error) {
    		console.log(error);
    	});

    })

    //ajax (modal) betöltés
    $(document).on("click", ".open-modal", function(e){
		e.preventDefault();
		const url = $(this).attr("href");
		const modalTitle = $(this).attr("data-title");

		preloader.fadeIn();

        var o = $(this);
		$.ajax({
			url: url,
			type: 'GET',
			dataType: 'html'
		})
		.done(function(data) {
			$("#admin-edit-modal .modal-body").html(data);
			$("#admin-edit-modal .modal-title").html(modalTitle);
            $("#admin-edit-modal").modal("show");
            o.trigger('modal-opened');
			preloader.fadeOut();
		})
		.fail(function(error) {
			console.log(error);
		});
	});

    //ha változtatom a felhasználó státuszát akkor a listában is meg kell változtatni
    $(document).on("click", "#active-user-checkbox", function(){
        const id = $(this).data("user-id");
        const userRow = $("#user-" + id);


        if($(this).prop('checked')){
            $(".status", userRow).html("Aktív");
        } else {
            $(".status", userRow).html("Inaktív");
            $('select[name="medium_id"]').prop('value',0);
        }
    });


    //köszöntő kép törlése

    $(document).on("click", ".deleteRandomImage", function(e){
        e.preventDefault();
        preloader.fadeIn();
        const method = "GET";
        const url = $(this).attr("href");
        $.ajax({
            url: url,
            type: method
        })
        .done(function() {
            preloader.fadeOut();
            $(e.target).parents("tr").fadeOut();
        })
        .fail(function(e) {
            preloader.fadeOut();
            alert("Hiba történt: " + e);
        });
    });




    //általános autoküldés változtatás esetén
    $(document).on("change", ".changeSubmit", function(e){
        e.preventDefault();
        if($(this).hasClass("ajax")){
            preloader.fadeIn();
            const method = $(this).attr("method");
            const url = $(this).attr("action");
            const data = $(this).serialize();
            // console.log(data);

            $.ajax({
                url: url,
                type: method,
                data : data,
            })
            .done(function() {
                // console.log("Siker");
                preloader.fadeOut();
            })
            .fail(function(e) {
                alert("Hiba történt: " + e);
                preloader.fadeOut();
            });
        } else {
            $(this).submit();
        }

    });
})
