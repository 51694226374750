require('./bootstrap');

Object.assign(window, require('./functions'));
Object.assign(window, require('./inits'));
require('./scrollEvents');
require('./charts');
require('./debug');
require('./fileManager');
require('./modals');
require('./ajaxForms');
require('./purchase');
require('./orders');
require('./userManager');
require('./customInputs');
require('./tagManager');
require('./COLumnORDer');

$(function(){

	// $("#homeSearch").change(function(e){
	// 	if( !$(e.target).hasClass('select2-search__field') ){
	// 		$(this).submit();
	// 	}
    // })
    $(document).on('keyup','.select2-search__field', function(e) {
        // console.log("select2 searc keyup");
        if(e.keyCode === 13){
            $(this).submit();
        }
     });

	$(document).on("click", ".openPreviewModal", function(){

		let img = '<img style="height:50vh;" src="' + $(this).attr("src") + '" />';
		img = img.replace('170x170', '1000x1000');
		img = img.replace('270x270', '1000x1000');

		$("#previewModal .modal-body").html(img);

		$("#previewModal").modal();
	});



	$(document).on("click", ".confirm", function(e){
		// e.preventDefault();
		if(confirm("biztos?")) return true;
		else return false;
	});



	$(".home-search input").click(function(e){
		e.preventDefault();
		return false;
	})

	$(document).on("keyup", ".home-search input", function(e){
		if($(this).val() == ""){
			e.preventDefault();
			$(".home-search .select2-tags").select2('close');
			return false;
		}
	})

})

// Főoldali keresés
$(function(){
	$("#homeSearch").on("submit", function(e){
		e.preventDefault();


		const search = $("#search-bar").select2("data");
		let link = $(this).attr("action") + "?view=" + $('.tiles-container').attr("data-view");

		$.each(search, function(index, el) {
			link += "&";

			if(el.id == el.text){
                if(parseInt(el.text) > 0){
                    // console.log(parseInt(el.text).toString().length, el.text.length);
                    if(parseInt(el.text).toString().length == el.text.length){
						link += "search[img_ids][]=" + el.id;
					} else {
						link += "search[keywords][]=" + el.id;
					}
				} else {
					link += "search[keywords][]=" + el.id;
				}
			} else {
				link += "search[tags][]=" + el.text;
			}
        });

        if($(this).hasClass('ajaxSearch')){
            preloader.fadeIn();
            $.ajax({
                type: "GET",
                url: link,
                dataType: "json",
                success: function (response) {
                    isPagination = true;
                    currentPage = 1;
                    // console.log("betöltés...");
                    $(".tiles-container").html(response.render);
                    window.history.pushState(false, "", link);

                    $(".resultCount").text(response.count);
                    $(".tiles-container")
                        .attr("data-last-page", response.lastPage)
                        .attr("data-view", response.view);

                    $(".filterForm").parents('.stick-up-section').html(response.filter_render);

					setSelect2();
                },
                error: function(e){
                    console.log(e);
                },
                complete: function(e){
                   preloader.fadeOut();
                }
            });
            return false;
        }

		location.href = link;

		return false;
	})
})


$(document).on("click", ".modal-image-wrapper", function(){
    $(this).parents('.image-data-column').toggleClass("w-100-animate");
    $(this).parents('.image-data-column').next('.image-data-column').toggleClass("w-100-animate");
})


$("#regType").change(function(event) {
	const type = $(this).val();
	const collapse = $("#regTypeCollapse");

	$(".row", collapse).hide();
	$(".row input", collapse).removeAttr("required");

	switch (type) {
		case "photographer":
				$(".display_name", collapse).show();
				$(".display_name input", collapse).attr("required", "required");
			break;
		case "customer":
				$(".use_photos_for", collapse).show();
				$(".use_photos_for input", collapse).attr("required", "required");
			break;
	}

	collapse.collapse("show");
});



//egyedi confirm üzenet
$(document).on("click", ".dfpConfirm", function(e, confirmed = false){
    if(!confirmed){
        e.preventDefault();
        let message = "Biztos végrehajtod a következő műveletet?";
        if($(this).data("confirm-message") !== undefined){
            message = $(this).data("confirm-message");
        }
        dfpConfirm(message, function(){
            $(e.target).trigger("click", [true]);
        });

        return false;
    }
})

function dfpConfirm(message, onComplete){
    $("#confirm-modal .modal-body").html(message);
    $("#confirm-modal").modal("show");
    $(".acceptConfirm").click(function(e){
        e.preventDefault();
        onComplete(true);
        $(".acceptConfirm").off("click");
    });
}
