var selected_purchases = {};

$(document).on('change.dfp', '.change-billing', function(e){

    $.ajax({
        type: "PUT",
        url: $(this).data('url'),
        data: $(this).serialize(),
        dataType : "json",
        success: function (data) {

        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });

});

$(document).on('click.dfp', '.use-this-address', function(e){

    var id = $(this).data('id');
    // console.log( id);
    $('#billing-'+id).find('input[name="billing_country"]').prop('value', $(this).data('billing_country'));
    $('#billing-'+id).find('input[name="billing_zipcode"]').prop('value', $(this).data('billing_zipcode'));
    $('#billing-'+id).find('input[name="billing_city"]').prop('value', $(this).data('billing_city'));
    $('#billing-'+id).find('input[name="billing_addr"]').prop('value', $(this).data('billing_addr'));
    $('#billing-'+id).find('input[name="billing_phone"]').prop('value', $(this).data('billing_phone'));
    $('#billing-'+id).find('input[name="billing_email"]').prop('value', $(this).data('billing_email'));
    $('#billing-'+id).find('input[name="billing_name"]').prop('value', $(this).data('billing_name'));
    $('#billing-'+id).find('input[name="billing_tax"]').prop('value', $(this).data('billing_tax'));
    $('#billing-'+id).trigger('change');

});

$(document).on('click.dfp', '#approve-generate-request', function(e){

    $.ajax({
        type: "POST",
        url: $(this).data('url'),
        dataType : "json",
        success: function (data) {
            if(data['status'] == 'NOTOK'){
                if($('.invoice-bill-error').length){
                    $('.invoice-bill-error').html(data['message']);
                }else{
                    $('#invoice-modal').find('.modal-footer').append('<span class="invoice-bill-error" style="color:red;position:absolute;left:1%;">'+data['message']+'</span>')
                }
            }
            else if(data['status'] == 'OK'){


                // ********************** myOrders
                if($('#need_order_filter_functions').length){

                    $('#orders_admin').find('form').submit();

                }// ********************** myOrders

                load_purchases();
                $("#invoice-modal .modal-body").html('');
                $("#invoice-modal").modal("hide");
            }
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });

});

$('#orders_admin').find('.select2-tags').on('change.dfp', function(){
    setTimeout(function(){
        $('#orders_admin', document).find('form').submit();
    },100);
});

$(document).on('change.dfp', '.purchases-filter', function(){
    var value = {};
    value['key'] = $(this).attr('id').substring(10);
    value['value'] = $(this).prop('value');

    $('input[name="page"]').prop('value',1);

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $(this).data('filterurl'),
        data: value,
        cache: false,
        success: function (data) {
            $('#orders_admin').find('form').submit();
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });
});

$(document).on('change.dfp', '.purchases-filter-reload', function(){
    $('#orders_admin').find('form').submit();
});

$(document).on('click.dfp', '.change-filter-date', function(){

    var value = {};

    if(typeof $(this).data('type') !== 'undefined'){
        value['type'] = $(this).data('type');
    }
    else{

        value['set_date'] = '';
        $('#purchases-start').prop('value', $(this).data('from'))
        $('#purchases-end').prop('value', $(this).data('to'));

    }

    value['key'] = 'start';
    value['value'] = $('#purchases-start').prop('value');
    value['key2'] = 'end';
    value['value2'] = $('#purchases-end').prop('value');

    $('input[name="page"]').prop('value',1);

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $(this).data('url'),
        data: value,
        cache: false,
        success: function (data) {

            if(typeof data['set_another_date'] !== 'undefined'){

                $('#purchases-start').prop('value', data['set_another_date']['start'])
                $('#purchases-end').prop('value', data['set_another_date']['end']);
                $('#month_qty').prop('value', data['set_another_date']['month_qty']);

            }

            $('#orders_admin').find('form').submit();
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });

});

$(document).on('click.dfp','.purchases-approve', function(e){
    e.preventDefault();
    preloader.fadeIn();
    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        headers: { "cache-control": "no-cache" },
        url: $(this).data('url'),
        cache: false,
        data:{
            'ids':[ $(this).data('id') ],
            'status':$(this).data('status')
        },
        success: function (data) {

            $('#orders_admin').find('form').submit();
            preloader.fadeOut();
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });

});





//KUKA LESZ HA MINDEN KÉSZ
$(document).on('change.dfp','.purchases-checkbox', function(){
    if($(this).is(':checked')){
        selected_purchases[$(this).data('id')] = '';
    }
    else{
        delete selected_purchases[$(this).data('id')];
    }
    // console.log(selected_purchases);
    // return false;
});
//----------------------





$(document).on('click.dfp','#create_szamlazz_mass', function(e){
    e.preventDefault();
    if(Object.keys(selected_purchases).length){
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: $(this).data('url'),
            cache: false,
            data:{
                'ids':Object.keys(selected_purchases)
            },
            success: function (data) {

                if($('.invoice-bill-error').length){
                    $('.invoice-bill-error').remove();
                }
                $("#invoice-modal .modal-body").html(data['html']);
                $("#invoice-modal").modal("show");


            },
            error: function (e) {
                console.log("ERROR : ", e);
            }
        });
    }

});

$(document).on('click', '#select_filtered_ids', function(e){
    let mode = false;
    if($(this).prop("checked")){
        mode = true;
        $('.purchases-checkbox.selectable').prop('checked', true);
    } else {
        $('.purchases-checkbox.selectable').prop('checked', false);
    }

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $(this).data('url'),
        cache: false,
        data:{
            'mode':mode
        },
        success: function (data) {
            selected_purchases = data[1];
            // console.log(data[1]);
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });
});

$(document).on('click.dfp','.edit_selected_ids', function(e){
    e.preventDefault();

    // if($(this).prop('value') == '' || $('#select_filtered_ids').attr('data-mode') != 'false'){
    //     return false;
    // }
    preloader.fadeIn();

    if(Object.keys(selected_purchases).length){
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: $(this).data('url'),
            cache: false,
            data:{
                status:$(this).data('status'),
                ids:Object.keys(selected_purchases)
            },
            success: function (data) {
                $('#orders_admin').find('form').submit();
            },
            error: function (e) {
                console.log("ERROR : ", e);
            },
            complete: function(xhr){

                preloader.fadeOut();
            }
        });

    }
    return false;

});

$(document).on('paginated.dfp',".order-container", function(e){
    // console.log('paginated');
    $.each(selected_purchases, function (key, val) {
        $('#order-'+key+'.selectable').prop('checked', true);
    });
});

$(document).on('change.dfp','.sales-filter-status', function(e){

    $(this).parent('form').submit();

});

$(document).on('change.dfp','.sales-checkbox', function(e){
    e.preventDefault();
    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $(this).data('url'),
        cache: false,
        data:{
            'select':$(this).data('id')
            ,'mode':($(this).prop('checked') ? 1 : 0)
        },
        success: function (data) {

            $('#request-this-amount').html(data[1]);
            $('#request-this-qty').html(data[2]);

        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });

    return false;

});

$(document).on('click.dfp','.select-all-sales', function(e){
    preloader.fadeIn();

    e.preventDefault();

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: $(this).data('url'),
        cache: false,
        data:{
            'select':'set'
            ,'mode':$(this).data('mode')
        },
        success: function (data) {

            $('#request-this-amount').html(data[1]);
            $('#request-this-qty').html(data[2]);
            preloader.fadeOut();

        },
        error: function (e) {
            console.log("ERROR : ", e);
            preloader.fadeOut();
            alert("Hiba történt, kérlek ellenőrizd a konzolt!");
        }
    });

    if($(this).data('mode') == 0){
        $('.sales-checkbox').prop('checked', false);
        $(e.target).data("mode", 1).text("Összes kijelölése");
    }
    else{
        $('.sales-checkbox').prop('checked', true);
        $(e.target).data("mode", 0).text("Kijelölés törlése");
    }

    return false;

});

$(function(){
    if($('.hidden-controller').length){
        $(document).on('click.dfp','.hidden-controller', function(){
            const id = $(this).data('hiddenid');
            if($('#hidden-elem-'+id).hasClass('d-none')){
                $('.hidden-elem').addClass('d-none');
                $('#hidden-elem-'+id).removeClass('d-none');
            }
            else{
                $('.hidden-elem').addClass('d-none');
                $('#hidden-elem-'+id).addClass('d-none');
            }

        });
    }
});













//számlázás
$(document).on('click.dfp','#outsider_invoice_mass', function(){
    set_invoice_out($(this).data("url"), get_checked_orders())
    return false;

});
$(document).on('click', '.purchases-invoice', function(e){

    if(!$(this).hasClass("szh")){
        if(confirm("Biztos hogy kiszámláztad?")){
            e.preventDefault();
            set_invoice_out($(this).attr("data-url"), [$(this).attr('data-id')]);
        }
    }
    else{
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: $('#create_szamlazz_mass').data('url'),
            cache: false,
            data:{
                'ids':[$(this).data('id')]
            },
            success: function (data) {

                if($('.invoice-bill-error').length){
                    $('.invoice-bill-error').remove();
                }
                $("#invoice-modal .modal-body").html(data['html']);
                $("#invoice-modal").modal("show");


            },
            error: function (e) {
                console.log("ERROR : ", e);
            }
        });
    }

});
function set_invoice_out(url, orders){
    if(orders.length){
        preloader.fadeIn();
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: url,
            cache: false,
            data:{
                'ids':orders,
                'status':'outsider_invoice'
            },
            success: function (data) {
                if(data[0]=="OK"){
                    // console.log(orders);
                    $.each(orders, function(index, id){
                        const checkbox = $(document).find('.purchases-checkbox[data-id="' + id + '"]');
                        console.log(checkbox);
                        checkbox.parents('tr').css({
                            backgroundColor: "#cbffbe",
                            opacity : 1
                        });
                        checkbox.parents('tr').find('.action-button-wrapper').html(`
                            <div class="d-inline-block m-1">
                                <i class="invoiceOfOut large bg-success" data-toggle="tooltip" data-original-title="Külső rendszerből számlázva" style="cursor:default;"></i>
                            </div>
                            <a class="m-1 storno" data-url="` + rootUrl + `/reverse-invoice/` + id + `" data-id="` + id + `">
                                <i class="invoiceStorno" data-toggle="tooltip" data-original-title="Számla sztornózása"></i>
                            </a>
                        `);

                    });
                } else {
                    alert("Hiba történt, kérlek ellenőrizd a  konzolt!");
                }
                preloader.fadeOut();
            },
            error: function (e) {
                preloader.fadeOut();
                alert("Hiba történt, kérlek ellenőrizd a  konzolt!");
                console.log("ERROR : ", e);
            }
        });
    } else {
        alert("Nincs kijelölve számlázandó tétel!");
    }
}
//-------






// Sztornózás
$(document).on('click.dfp','#storno_mass', function(){
    set_storno($(this).data('url'), get_checked_orders());
    return false;
});

$(document).on('click.dfp','.storno', function(e){
    e.preventDefault();
    set_storno($(this).attr('data-url'),[$(this).attr('data-id')]);
    return false;
});

function set_storno(url, selectedOrder){
    if(selectedOrder.length){
        preloader.fadeIn();
        if(confirm('Biztos hogy sztornózod?')){
            $.ajax({
                type: "POST",
                enctype: 'multipart/form-data',
                url: url,
                cache: false,
                data:{
                    'ids':selectedOrder,
                    'status':'storno'
                },
                success: function (data) {
                    // $('#orders_admin').find('form').submit();
                    $.each(selectedOrder, function(index, id){
                        const checkbox = $(document).find('.purchases-checkbox[data-id="' + id + '"]');
                        checkbox.parents('tr').css({
                            backgroundColor:"#ffbebe",
                            opacity:0.4
                        });
                        checkbox.parents('tr').find('.action-button-wrapper').html(`
                            <a data-value="szamlazz.hu" data-url="` + rootUrl + `/purchases-invoice" data-id="` + id + `" class="purchases-invoice szh">
                                <i class="szamlazzhu large" data-toggle="tooltip" data-original-title="Számlázás számlázz.hu használatával"></i>
                            </a>
                            <a data-value="outsider_invoice" data-url="` + rootUrl + `/purchases-invoice" data-id="` + id + `" class="purchases-invoice">
                                <i class="invoiceOfOut large" data-toggle="tooltip" data-original-title="Külső rendszerből számlázva"></i>
                            </a>
                        `);
                    });
                    preloader.fadeOut();
                },
                error: function (e) {
                    console.log("ERROR : ", e);
                    preloader.fadeOut();
                }
            });
        }
    }
}
//------------






function get_checked_orders(){
    let orders = [];
    $.each($('.purchases-checkbox'), function(index, value){
        const obj = $(value);
        if(obj.prop("checked")){
            orders.push($(value).data("id"));
        }
    })
    return orders;
}
