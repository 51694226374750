
var usersearch_t;

$(function(){

    //média és user törlése
    $(document).on("click", ".delete-media", function(e){
		e.preventDefault();

    	const form = $(this).parents("form");
    	const url = form.attr("action");
    	const data = form.serialize();
    	const mediaId = $(this).attr("data-media-id");

        if (confirm('Biztosan törlöd?')) {
        	preloader.fadeIn();

        	$.ajax({
        		url: url,
        		type: 'POST',
        		data: data
        	})
        	.done(function(data) {
        		// console.log(data);
        		// console.log("Törölve");
        		$("#" + mediaId).fadeOut(300, function(){
        			$("#" + mediaId).remove();
        		});
        		preloader.fadeOut();

        	})
        	.fail(function(error) {
        		console.log(error);
        	});
        }

    });

    //felhasználó jelszómódosítás
    $(document).on("click", "#admin-update-password button", function(e){
		e.preventDefault();

    	const form = $(this).parents("form");
    	const url = form.attr("action");
    	const data = form.serialize();

		preloader.fadeIn();

		$.ajax({
			url: url,
			type: 'POST',
			dataType:'json',
			data: data
		})
		.done(function(data) {
			// console.log(data);
			//$('#admin-update-password').find('input').prop('value','');


			$('#admin-update-password-message').removeClass('d-none');
			$('#admin-update-password-message').removeClass('text-success');
			$('#admin-update-password-message').removeClass('text-danger');
			$('#admin-update-password-message').addClass('text-success');
			$('#admin-update-password-message').html(data[0]);

			preloader.fadeOut();

		})
		.fail(function(error) {
			var response = JSON.parse(error.responseText);

			if(typeof response['errors'] != 'undefined'){

				$('#admin-update-password-message').removeClass('text-success');
				$('#admin-update-password-message').removeClass('text-danger');
				$('#admin-update-password-message').addClass('text-danger');
				$('#admin-update-password-message').html('');
				$.each(response['errors'], function(i, item) {
					$.each(item, function(i2, item2) {
						$('#admin-update-password-message').append(item2 + '<br/>');
					});
				});
				$('#admin-update-password-message').removeClass('d-none');
			}
			else{
				console.log(error);
			}

			preloader.fadeOut();

		});
		return false;

    });


	if($('#user-search-admin').length){

		$('#user-search-admin').on('submit.dfp', function(e){

			e.preventDefault();

			return false;
		});

		$('#user-search-admin').find('input, select').on('change.dfp', function(e){

			e.preventDefault();

			//$('#user-search-admin').submit();

            $.ajax({
                type: "POST",
                enctype: 'multipart/form-data',
				url: $('#user-search-admin').attr('action'),
				dataType:'json',
                cache: false,
                data:{
					username:$('#user-search-admin').find('input[name="username"]').prop('value'),
					role:$('#user-search-admin').find('select[name="role"]').prop('value'),
					status:$('#user-search-admin').find('select[name="status"]').prop('value'),
					order:$('#user-search-admin').find('select[name="order"]').prop('value'),
					mode:$('#user-search-admin').find('select[name="mode"]').prop('value')
				},
                success: function (data) {
					$('#admin-user-list-wrapper').html(data[0]);
					$('#admin-user-list-pagination').html(data[1]);
					window.load_user_function();
                },
                error: function (err) {
                    console.log("ERROR : ", err);
                }
            });

			return false;

		});

		$('#user-search-admin').find('input').on('dblclick.dfp', function(e){

			$(this).prop('value','').trigger('change');

		});

		$('#user-search-admin').find('input').on('keyup.dfp', function(e){

			clearTimeout(usersearch_t);
			usersearch_t = setTimeout(function(o){

				o.trigger('change');

			},400, $(this));

		});

	}


})


window.load_user_info_filter = function load_user_info_filter(){

	$('.user-info-filter').off('change.dfp');
    $('.user-info-filter').on('change.dfp', function(e){

        window.load_user_info();

    });

	$('.user-info-filter').off('click.dfp');
    $('.user-info-filter').on('click.dfp', function(e){

		if(typeof $(this).attr('data-from') != 'undefined'){
			// console.log('ossz');

			$('#user-info-filter-start').prop('value', $(this).data('from')),
			$('#user-info-filter-end').prop('value', $(this).data('to')),

			$('#user-info-filter-end').trigger('change');
			return false;
		}

		return true;

    });



};

window.load_user_info = function load_user_info(){

	if( $('.user-info-filter').length ){

		preloader.fadeIn();
		$.ajax({
			type: "POST",
			enctype: 'multipart/form-data',
			url: $('.user-info-filter').data('url'),
			cache: false,
			data:{
				'from': $('#user-info-filter-start').prop('value'),
				'to': $('#user-info-filter-end').prop('value')
			},
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: function (data) {
				$('#user-statistic-wrapper').html(data[0]);
				$( "#admin-edit-modal" ).trigger('shown.bs.modal');
				preloader.fadeOut();
			},
			error: function (e) {
				alert("Hiba történt, kérlek ellenőrizd a konzolt!");
				console.log("ERROR : ", e);
				preloader.fadeOut();
			}
		});

	}


};

window.load_user_function = function load_user_function(){

    $('.user-info').on('modal-opened.dfp',function(){

		// console.log('user-modal-opened');
		window.load_user_info_filter();
		window.load_user_info();

    });

}

$(function(){
    window.load_user_function();
});
