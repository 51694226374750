
window.colord_func = function set_colord_func(og, ot, mode){

    if(typeof og == 'undefined'){
        og = '';
    }
    
    if(typeof ot == 'undefined'){
        ot = '';
    }
    
    if(typeof mode == 'undefined'){
        mode = '';
    }
    
    if(mode != '' && mode != 'asc' && mode != 'desc'){
        mode = 'desc';
    }


    var colord_og = {};

    if($('.colord').length){

        $('.colord').each(function(){

            if(
                $(this).data('og')
                && $(this).data('ot')
                && $(this).data('ou')

            ){
                colord_og[$(this).data('og')] = $(this).data('og');
                if(!$(this).find('.colord-'+$(this).data('og')).length){

                    $(this).append('<span class="colord-function colord-'+ $(this).data('og') +'" data-mode="desc">');
                    
                    if(
                        ot
                        && og
                        && mode
                        && ot == $(this).data('ot')
                        && og == $(this).data('og')
                    ){

                        if(mode == 'asc'){
                            $(this).find('.colord-'+$(this).data('og')).html('<i class="filterArrowsDown"></i>');
                            $(this).find('.colord-'+$(this).data('og')).attr('data-mode','asc');
                        }
                        else{
                            mode = 'desc';
                            $(this).find('.colord-'+$(this).data('og')).html('<i class="filterArrowsUp"></i>');
                            $(this).find('.colord-'+$(this).data('og')).attr('data-mode','desc');
                        }

                    }
                    else{
                        $(this).find('.colord-'+$(this).data('og')).html('<i class="filterArrows"></i>');
                    }
                }
            }

        });

        Object.entries(colord_og).forEach(([key, value]) => {

            $('.colord-'+key).off('click.dfp');
            $('.colord-'+key).on('click.dfp', function(){

                var mode    = $(this).attr('data-mode');
                var url     = $(this).parent().data('ou');
                var table   = $(this).parent().data('ot');
                var group   = $(this).parent().data('og');

                $('.colord-'+group).html('<i class="filterArrows"></i>');
                if(mode == 'desc'){
                    $(this).html('<i class="filterArrowsDown"></i>');
                    $(this).attr('data-mode','asc');
                }
                else{
                    mode = 'asc';
                    $(this).html('<i class="filterArrowsUp"></i>');
                    $(this).attr('data-mode','desc');
                }

                var o = $(this).parent();
                var s = $(this);
                preloader.fadeIn();
                $.ajax({
                    type: "POST",
                    enctype: 'multipart/form-data',
                    dataType:'json',
                    url: url,
                    cache: false,
                    data:{
                        'colord-group': group,
                        'colord-table': table,
                        'colord-mode': mode,
                    },
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    success: function (data) {

                        if($('.colord-content[data-og="'+ o.data('og') +'"]').length){

                            $('.colord-content[data-og="'+ o.data('og') +'"]').html(data[0]);
                            $('.colord-content[data-og="'+ o.data('og') +'"]').trigger('colord-happened', [o.data('og'), o.data('ot'), s.attr('data-mode')]);

                        }

                    },  
                    error: function (e) {
                        alert("Hiba történt, kérlek ellenőrizd a konzolt!");
                        console.log("ERROR : ", e);
                    },  
                    complete: function (e) {
                        preloader.fadeOut();
                    }
                });


            });

        });


    }

}


$(function(){
    
	window.colord_func();
    
});
