$(function(){


    //fizetésigénylések
    if($('#pay_requests')){
        $('.pay').on('click.dfp', function(e){
			// console.log($('#pay-modal').find('#page').prop('value'));
            e.preventDefault();
            $('#pay-modal').find('#id').prop('value', $(this).data('id'));
            $('#pay-modal').find('#photographer').prop('value', $('select[name="photographer"]').prop('value'));
            $('#pay-modal').find('#status').prop('value', $('select[name="status"]').prop('value'));
            $('#pay-modal').find('#payed_amount').prop('value', $(this).data('amount'));
            $('#pay-modal').modal('show')

            return false;

        });
    }

    //rendelések
    if($('#orders_admin')){

        $('.changeprice').on('change.dfp', function(e){

            e.preventDefault();


            $.ajax({
                type: "POST",
                url: $(this).data('url'),
                dataType : "json",
                data:{
                    'id':$(this).data('id'),
                    'itemid':$(this).data('itemid'),
                    'price':parseInt($(this).prop('value'),10)
                },
                success: function (data) {
                    console.log("status : ", data['status']);
                    if(data['status'] == 'OK'){
                        $('#order-price-'+data['id']).html(data['price']);
                    }
                },
                error: function (e) {
                    console.log("ERROR : ", e);
                }
            });


            return false;

        });

    }


});
