

var charts = {}


function change_statistic_data(id){

	if($('.statistic-tab.active').length){

		preloader.fadeIn();

		$.ajax({
			type: "POST",
			enctype: 'multipart/form-data',
			url: $('.statistic-tab.active').data('url'),
			cache: false,
			data:{
				'tab': $('.statistic-tab.active').attr('id')
			},
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: function (data) {
				if(typeof data['charts'] !='undefined'){
					charts = data['charts'];
					loadChart(charts);
				}
				if(typeof data['data'] !='undefined'){

					Object.entries(data['data']).forEach(([key, value]) => {
						$('#'+key).html(value);
					});

				}
				if(typeof data['table'] !='undefined'){

					Object.entries(data['table']).forEach(([key, value]) => {
						$('#'+key).html(value);
					});

				}
				window.colord_func();
				window.load_user_function();

				$('.colord-content').off('colord-happened');
				$('.colord-content').on('colord-happened',function(e, og, ot, mode){

					window.colord_func(og, ot, mode);
					//console.log('colord.function.happened');

				});

				load_statistic_event_handler();
				preloader.fadeOut();
			},
			error: function (e) {
				alert("Hiba történt, kérlek ellenőrizd a konzolt!");
				console.log("ERROR : ", e);
				preloader.fadeOut();
			}
		});

	}




}
function load_statistic_event_handler(){

	if($('.unsettled_pay').length){

		$('.unsettled_pay').off('click.dfp');
		$('.unsettled_pay').on('click.dfp', function(e){

			e.preventDefault();

			$('#this_invoice_paid').find('#id').prop('value', $(this).data('id'));
			$('#this_invoice_paid').modal('show');

			return false;

		});

		$('#unsettled_invoice_pay_confirm').off('click.dfp');
		$('#unsettled_invoice_pay_confirm').on('click.dfp', function(e){

			e.preventDefault();

			preloader.fadeIn();
			$.ajax({
				type: "POST",
				enctype: 'multipart/form-data',
				url: $(this).data('url'),
				cache: false,
				data:{
					'ids':[ $('#this_invoice_paid').find('#id').prop('value') ],
					'status':'payed'
				},
				success: function (data) {

					$('#unsettled-'+$('#this_invoice_paid').find('#id').prop('value')).remove();
					$('#this_invoice_paid').modal('hide');
					preloader.fadeOut();

				},
				error: function (e) {
					alert("Hiba történt, kérlek ellenőrizd a konzolt!");
					console.log("ERROR : ", e);
					preloader.fadeOut();
				}
			});


			return false;

		});

	}

}

$('.statistic-filter').on('change.dfp', function(){

	preloader.fadeIn();
	$.ajax({
		type: "POST",
		enctype: 'multipart/form-data',
		url: $(this).data('url'),
		data:{
			'statistic_start':$('#statistic-filter-start').prop('value')
			,'statistic_end':$('#statistic-filter-end').prop('value')
		},
		cache: false,
		success: function (data) {
			change_statistic_data();
			preloader.fadeOut();
		},
		error: function (e) {
			alert("Hiba történt, kérlek ellenőrizd a konzolt!");
			console.log("ERROR : ", e);
			preloader.fadeOut();
		}
	});

});

$('.statistic-all').on('click.dfp', function(){

	preloader.fadeIn();
	$.ajax({
		type: "POST",
		enctype: 'multipart/form-data',
		url: $(this).data('url'),
		cache:false,
		data:{
			'statistic_start':$(this).data('from')
			,'statistic_end':$(this).data('to')
			,'mode':$(this).data('mode')
			,'type':$(this).data('type') ? $(this).data('type') : ''
			,'year_qty':$('#year_qty').prop('value')
			,'month_qty':$('#month_qty').prop('value')
		},
		cache: false,
		success: function (data) {
			$('#statistic-filter-start').prop('value',data[0]);
			$('#statistic-filter-end').prop('value',data[1]);
			$('#month_qty').prop('value',data[2]['month_qty']);
			$('#year_qty').prop('value',data[2]['year_qty']);
			change_statistic_data();
			preloader.fadeOut();
		},
		error: function (e) {
			alert("Hiba történt, kérlek ellenőrizd a konzolt!");
			console.log("ERROR : ", e);
			preloader.fadeOut();
		}
	});

});

$('.statistic-tab').on('click.dfp', function(){

	setTimeout(function(){change_statistic_data();},10);

});


$(function(){

	if($('.statistic-tab').length){
		change_statistic_data();
	}

});

var ctx;
var myChart = {};
function loadChart(charts){

	//########################################
	// Vásárlások darabszáma
	//########################################

		$.each(charts, function(index, chart) {

			if(Object.keys(myChart).length && typeof myChart[index] != 'undefined'){
				myChart[index].destroy();
			}

			ctx = document.getElementById(index).getContext('2d');
			myChart[index] = new Chart(ctx, {
			    type: 'bar',
			    data: {
			        labels: Object.keys(chart),
			        datasets: [{
			            label: [],
			            data: Object.values(chart),
                        borderWidth: 1,
                        backgroundColor: "#7EBA28"
			        }]
			    },
			    options: {
                    legend:{ display: false },
			        scales: {
			            yAxes: [{
			                ticks: {
			                    beginAtZero: true
			                },
                            gridLines:{
                              color:"rgba(255,255,255,0.5)",
                              zeroLineColor:"rgba(255,255,255,0.5)"
                            }
			            }]
                    },
                    responsive: true
			    }
			});

		});
	//########################################
	//########################################

}








//########################################
// Felhasználói chartok
//########################################
var myUserCharts = {};
$( "#admin-edit-modal" ).on('shown.bs.modal', function(){

	if($('#admin-user-profile').length){

		var charts = {};

		charts['qty_by_day'] = $('#qty_by_day').data('data');
		charts['amount_by_day'] = $('#amount_by_day').data('data');
		$.each(charts, function(index, chart) {

			if(Object.keys(myUserCharts).length && typeof myUserCharts[index] != 'undefined'){
				myUserCharts[index].destroy();
			}

			if(document.getElementById(index)){
				// console.log('load');
				ctx = document.getElementById(index).getContext('2d');
				myUserCharts[index] = new Chart(ctx, {
					type: 'bar',
					data: {
						labels: Object.keys(chart),
						datasets: [{
							data: Object.values(chart),
							borderWidth: 1
						}]
					},
					options: {
						scales: {
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
						}
					}
				});
			}

		});

	}
	return true;


});



//########################################
//########################################
