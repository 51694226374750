window.isPagination = true;
window.currentPage = 1;
var preloader = $("<div></div>").css({
    width:"100%",
    height:"100%",
    position:"fixed",
    background:"rgba(255,255,255,0.6)",
    display: "none",
    top:0,
    left:0,
    textAlign:"center",
    zIndex : 9999999
});
$(function(){

	setSelect2();

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

	//tolltips
	$('[data-toggle="tooltip"]').tooltip();


	//lenyíló menük
	$(document).on("click", ".allDataListCollapse", function(){
		if($(this).hasClass("collapsed")){
			$("i", this).removeClass("open");
		} else {
			$("i", this).addClass("open");
		}
	});

    //init loader
    preloader.append(
    	$("<div></div>")
	    	.css({
	    		width : 0,
	    		padding : 15,
	    		background : "#7eba29",
	    		transition : "0.3s",
	    		position : "absolute",
	    		bottom : 0,
	    		left : 0,
	    		textAlign : "center",
	    		fontSize : "1.5rem",
	    		color : "#fff"
	    	})
	    	.addClass("loader-status")
    );
    preloader.append(
        $("<img>").attr("src", rootUrl + "/images/preloaders/dfp_green_loader.gif").css({
            marginTop:"35vh"
        })
    );
    $("body").append(preloader);

	// Welcome images
    if($('#randomimage_admin')){
        $('.random-image-screen-selector').on('change.dfp', function(e){

            e.preventDefault();


            $.ajax({
                type: "POST",
                url: $(this).data('url'),
                dataType : "json",
                data:{
                    'option':$(this).prop('value'),
                },
                success: function (data) {
                    // console.log("change : ", data[0]);
                },
                error: function (e) {
                    console.log("ERROR : ", e);
                }
            });


            return false;

        });
    }

});


module.exports = {
	preloader : preloader,
	isPagination : isPagination,
}
