$(function(){

    $(document).on("change", "select", function(){
        setTimeout(function(){
            $('.group').parents("li").addClass("groupParent");
        },50);
    });

	$('#keywords-wrapper .nav-item').click(function(e) {
        preloader.fadeIn();
		$.ajax({
			type 		: "GET",
			url 		: $(this).data('url'),
			success: function(data) {
				$('.tab-pane.active').html(data['view']);
				setSelect2();
                $('.group').parents(".select2-selection__choice").addClass("groupParent");
            },
			error: function(data) {

			},
			complete: function() {
				preloader.fadeOut();
			}
		});

	});

    $("#keywords-wrapper .nav-link.active").trigger("click");

	$(document).on("click", "#tagsContent", function(){
		$(".tag-editor-menu").remove();
		$(".select2-selection__choice").removeClass("editing");
	});

	$(document).on("click", "#tagsContent .select2-selection__choice", function(){
		const tagId = $(".customTag", this).attr("data-id");
		window.open(
		  "albums?view=index&search[tags][]=" + tagId,
		  '_blank' // <- This is what makes it open in a new window.
		);
	});

	$(document).on("contextmenu", "#tagsContent .select2-selection__choice", function(e){
		e.preventDefault();

		let editorMenu = $("<div></div>");


		const tag = $(this);
		const x = e.pageX;
		const y = e.pageY + 15;
		const tagId = $(".customTag", this).attr("data-id");

		$(".tag-editor-menu").remove();
		$(".select2-selection__choice").removeClass("editing");

		tag.addClass("editing");

		editorMenu.css({
			top:y,
			left:x
		});
		editorMenu.addClass("tag-editor-menu");
		editorMenu.attr("data-id", tagId);
		editorMenu.append('<input class="tagInput" type="text" value="' + $(".customTag", tag).text() + '">');
		editorMenu.append('<input class="saveTag btn btn-primary text-white btn-sm mx-2" type="button" value="Mentés">');
		editorMenu.append('<input class="deleteTag btn btn-primary text-white btn-sm mx-2" type="button" value="Törlés">');
		$("body").append(editorMenu);

	});

	$(document).on("click", ".saveTag", function(){
        let message = "";
		let val = $(".tag-editor-menu .tagInput").val();
        const tagId = $(".tag-editor-menu").attr('data-id');
        const currentTag = $('option[value="' + tagId + '"]');
		tagManager(tagId, "update", val, function(result){
            const select = currentTag.parents("select");
            const editedTags = [];

			$(".tag-editor-menu").fadeOut();

            $.each(result, function(index, value){
                if(value.status == "created"){
                    var newOption = new Option(value.tag_name, value.tag_id, true, true);
                    $(newOption).insertAfter(currentTag);
                    select.trigger("change");
                    editedTags.push(value.tag_id);
                    message += "Új kulcsszó létrehozása: " + value.tag_name + "<br>";
                }
                if(value.status == "updated"){
                    setTimeout(function(){
                        $('span[data-id="' + tagId + '"]').text(value.tag_name);
                        $('option[value="' + tagId + '"]').text(value.tag_name);
                        $('span[data-id="' + tagId + '"]').parents("li")
                            .removeClass("active")
                            .removeClass("editing");
                    },100);
                    editedTags.push(tagId);

                    message += "Meglévő kulcsszó módosítása: " + value.tag_name + "<br>";
                }
                if(value.status == "merged"){
                    $('span[data-id="' + tagId + '"]').parents("li").fadeOut(500, function(){
                        $('option[value="' + tagId + '"]').remove();
                    });


                    let tagCount = parseInt($('.tab-pane.active .tagCount').text());
                    tagCount--;
                    $('.tagCount').text(tagCount);
                    editedTags.push(tagId);
                    message += "Meglévő kulcsszó egyesítése: " + value.tag_name + "<br>";
                }
                if(value.status == "not_modified"){
                    message += "A következő kulcszó nem módosult: " + value.tag_name + "<br>";
                }
            })





            setTimeout(function(){
                $.each(editedTags ,function(index, value){
                    $('.customTag[data-id="' + value + '"]').parents('li').css("background", "#ffe9c0");
                });
            },100);
            const warning = $('<div class="bg-warning">' + message + '</div>');
            warning.css({
                width:"100%",
                padding:"30px",
                position:"fixed",
                top:0,
                left:0,
                zIndex : 99999
            });
            $("body").append(warning);
            setTimeout(function(){
                warning.fadeOut();
                warning.remove();
            },5000);

		});
	});

	$(document).on("click", ".deleteTag", function(){
		const tagId = $(".tag-editor-menu").attr('data-id');
		if(confirm("Biztos hogy törlöd?")){
			tagManager(tagId, "remove", "", function(result){
                if(result.status == "deleted"){
                    $('span[data-id="' + tagId + '"]').parents('.select2-selection__choice').fadeOut(500, function(){
                        const select = $('option[value="' + tagId + '"]').parents("select");
                        $('option[value="' + tagId + '"]').remove();
                        select.trigger("change");
                    });
					$(".tag-editor-menu").fadeOut();

					let tagCount = parseInt($('.tab-pane.active .tagCount').text());
					tagCount--;
					$('.tagCount').text(tagCount);
				}
			})
		}
	});

    $(document).on("change", '#newTagForm [data-tag="category"]', function(e){
        preloader.fadeIn();
        let categoryID = $(e.target).select2("data");
        const url = $(categoryID[0].element).attr("data-group-url")
        $.ajax({
            type: "GET",
            url: url,
            dataType: "json",
            success: function (response) {
                const obj = $('[data-tag="group"]');
                const noGroup = $($('option', obj)[0]);
                obj.empty();
                obj.append(noGroup);
                $.each(response['tagGroups'], function (index, value) {
                    obj.append('<option value="' + value.id + '">' + value.title + '</option>');
                });

            },
            error: function(e){
                alert("Hiba történt, kérlek ellenőrizd a konzolt!");
                console.log(e);
            },
            complete: function(){
                preloader.fadeOut();
            }
        });

    });

	function tagManager(tagId, option, val, onEnd = function(){}){
		preloader.fadeIn();
		let url, type, data;

		if(option == "update"){
			url = $("#tagUpdateUrl").val();
			type = "PUT";
			data = {
				tag: val,
			};
		}
		if(option == "remove") {
			url = $("#tagDeleteUrl").val();
			type = "DELETE";
		}

		url = url.replace("##tagid", tagId);

		$.ajax({
			url: url,
			type: type,
			dataType: 'json',
			data : data
		})
		.done(function(e) {
			console.log(e);
			onEnd(e);
		})
		.fail(function(e) {
			alert("Hiba történt!");
			console.log(e);
		})
		.always(function() {
			preloader.fadeOut();
		});

	}

})
