

function alert(value){
    $("#alert-modal .modal-body").html(value);
    $("#alert-modal").modal("show");
}

function setSelect2(selectWrapper){
	//select2  beállítása kulcsszavakhoz
	const selects = $('.select2-tags');
	if(selects.length > 0){
		$.each(selects, function(index, el) {
            const select = $(el);
            let config = {};
            let tagCatIds = select.data('tag_cat_id');

			if(!select.hasClass("loaded")){
				$(el).addClass("loaded");

                if(select.data("type") != "simple"){
                    if(String(tagCatIds).search(",") !== -1){
                        tagCatIds = tagCatIds.split(",");
                    } else tagCatIds = [tagCatIds];

                    config = {
                        // minimumInputLength : 2,
                        minimumResultsForSearch: -1,
                        tags: $(el).data("add-enabled"),
                        tokenSeparators: [','],
                        placeholder:  $(el).attr("placeholder"),
                        "language": {
                            "noResults": function(){
                            return "Nincs találat";
                            },
                            "searching": function(){
                            return "Keresés...";
                            },
                            "loadingMore": function(){
                            return "További találatok betöltése...";
                            }
                        },
                        templateSelection : function(obj){
                            let classes = ["customTag"];

                            if($(obj.element).data("group")){
                                classes.push("group");
                            }

                            return $('<span>' + obj.text + '</span>').attr("data-id", obj.text).addClass(classes);
                        }
                    }
                }

				if(select.data('url') != undefined){
					config.ajax = {
					    url: select.data('url'),
					    dataType: 'json',
					    type: 'POST',
					    data: function(params){
					    	return {
					    		'search' : params.term,
						    	'tag_cat_id' : tagCatIds,
						    	'page' : params.page
					    	}
					    },
                        processResults: function(data, params) {

                            const el = $(this.container.$results);
                            const wrapper = this.container.$element.parents("[data-color-code='true']");

                            //színezéshez class nevek hozzáadása
                            if(wrapper.length > 0){
                                if (data.results.length === 0 || !data.match) {
                                    el.addClass("no-result");
                                    el.removeClass("match");
                                } else {
                                    el.removeClass("no-result");
                                    el.addClass("match");
                                }
                            }


                            return {
                                results: data.results.map(function(item) {
                                    return { id: item.id, text: item.text };
                                }),
                                pagination: {
                                    more: data.more
                                }
                            };

                        }
					}
				}

                select.select2(config);

			} else {
				// console.log("már betöltött nem kell újra");
			}
		});
	}
}

function loaderIndicator(n, all, onComplete){
	// console.log("növelés");

	const loaderIndicator = $(".loader-status", preloader);
	const percent = n / all * 100;

	loaderIndicator.css("width", percent + "%");
	loaderIndicator.text(n + "/" + all);

	if(percent == 100) {
		setTimeout(function(){
			onComplete();

			setTimeout(function(){
				loaderIndicator.css({
					width : 0
				});
			},500);

		},500);
	}
}

module.exports = {
	alert : alert,
	setSelect2 : setSelect2,
	loaderIndicator : loaderIndicator
}
